.collectionResults {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 1fr 1fr auto 1fr;
  width: 100%;
}
.collectionResults__header {
  display: flex;
  justify-content: flex-start;
  grid-column: 1/-1;
  grid-row: 2;

  font-size: var(--font-size-h1);
}
.collectionResults__actionBar {
  grid-column: 1/-1;
  grid-row: 1;
}
/* .header_text {
  font-size: 3.6rem;
  margin: 3.6rem 0;
  color: white;
  background-color: black;
} */
.collectionResults__songList {
  grid-row: 3/3;
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-bottom: 1px solid #f2f2f2;
}
.search__queryText {
  font-size: var(--font-size-h1);
  font-weight: 400;
  line-height: 1.3;

  margin-left: 4rem;
}
@media only screen and (min-width: 992px) and (min-device-height: 768px) and (orientation: landscape) {
  .collectionResults {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 1fr 1fr auto 1fr;
  }
  .collectionResults__header {
    display: flex;
    justify-content: flex-start;
    grid-column: 1/-1;
    grid-row: 2;

    font-size: var(--font-size-h1);
  }
  .collectionResults__actionBar {
    display: flex;
    justify-content: flex-end;
    grid-column: 3/-1;
    grid-row: 1;
  }
  /* .header_text {
    font-size: 3.6rem;
    margin: 3.6rem 0;
    color: white;
    background-color: black;
  } */
  .collectionResults__songList {
    grid-row: 3/3;
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-bottom: 1px solid #f2f2f2;
  }
  .search__queryText {
    font-weight: 400;
    line-height: 1.3;

    margin-left: 4rem;
  }
}
