/* Regular */
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}
/* Medium */
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}
/* Semi-Bold */
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url('./fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
/* Bold */
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* 10px / 16px = 0.625 = 62.5%
  1 rem = 10px */
  font-size: 62.5%;
}

body {
  /* margin: 0; */
  position: relative;

  background-color: var(--background-color);
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
.infinite-scroll-component__outerdiv {
  width: 100%;
}
